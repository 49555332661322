import './header.css';

const Header = () =>{
    return <>
   <div className='hdr_box'>
    <div className='hdr_logo'>
    
     <h1 className=''>Finwelco</h1>
    </div>
    <div className='hdr_links'>
     <span>Home</span>
     <span>About</span>
     <span>Insurance</span>
     <span>Page</span>
     <span>Contact</span>


    </div>
    <div className='hdr_btns'>
        <button className='hdr_btns_login'>Login</button>

        <button className='hdr_btns_signup'>Signup</button>
    </div>
    <span className='hdr_menu_btn'>
    <img width="50" height="50" src="https://img.icons8.com/ios/50/FFFFFF/menu--v1.png" alt="menu--v1"/>
    </span>
   </div>
    </>
}
export default Header;