
import { Routes, Route } from "react-router-dom";
import Home from "./components/home/Home.jsx"
import { WebRoutes } from "./routes.jsx";
import BaseComponent from "./components/BaseComponent.jsx";
import './App.css';

function App() {
  return (
   <>
   <Routes>
      <Route path={WebRoutes.HOME} element={<BaseComponent />}>
      
      <Route path={WebRoutes.HOME} element={<Home/>}/>
      </Route>

      


      </Routes>
   </>
  );
}

export default App;
