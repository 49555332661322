import './home.css';
import React, {  useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import homeImgTop from "../../assets/Frame 1803.png"
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import sliderImg1 from "../../assets/Insurance_5 1.png"
import sliderImg2 from "../../assets/Insurance_46 1.png"
import sliderImg3 from  "../../assets/Insurance_19 1.png"
import sliderImg4 from "../../assets/Insurance_14 2.png"
import sliderImg5 from "../../assets/Insurance_11 1.png"
import partnerImg1 from "../../assets/sbi-life-insurance-vector-logo-2022 1.png"
import partnerImg2 from "../../assets/Bajaj-Allianz-Life-Insurance-Logo 1.png"
import partnerImg3 from "../../assets/max-life-insurance-logo-9FE4AFADE1-seeklogo 1.png"
import partnerImg4 from "../../assets/Pnb-Metlife-Logo-Vector 1.png"
import partnerImg5 from "../../assets/analjit-in-exploratory-talks-to-merge-birla-sun-and-max-life 1.png"

import mfundsImg1 from "../../assets/ABSL_Logo_Square 1.png";
import mfundsImg2 from "../../assets/1538139609_pWFHYC_cropped_94_-11234 1.png";
import mfundsImg3 from "../../assets/hdfc-mutual-fund-vector-logo 1.png";
import mfundsImg4 from "../../assets/nippon-india-mutual-fund3594 1.png";
import mfundsImg5 from "../../assets/invesco_mf_logo_blue_pos_rgb 1.png";

import genInsImg1 from "../../assets/63f4ab5f7c6c9c502177ec80_logo__ICICI 1.png"
import genInsImg2 from "../../assets/iffco-tokio-logo-english 1.png"

import graffiti from "../../assets/Asset 2 1.png"

import blogImg1 from "../../assets/Rectangle 63 (1).png"
import blogImg2 from "../../assets/Rectangle 63 (2).png"
import blogImg3 from "../../assets/Rectangle 63.png"

import blogAuthor1 from "../../assets/Ellipse 6.png"
import blogAuthor2 from "../../assets/Ellipse 6 (1).png"
import blogAuthor3 from "../../assets/Ellipse 6 (2).png"

const Home = () =>{
      //aos animation
useEffect(() => {
    Aos.init({ duration: 1000 })
  }, []);
    return <>
    <div className="Home_box" >
     <div className='home_top_ctr'>
      <div data-aos="zoom-in-right" className='home_top_ctr_left'>
       <span style={{fontSize:"50px",color:"#092854"}}>
        <b>
        Insurance cannot be complicated anymore
        </b>
       </span>
       <span style={{color:"#1965cf",fontSize:"23px",fontWeight:"600"}}>Plan your protection with health, wealth and Life cover</span>
       <div className='Home_form_div'>
      
        <input placeholder="Enter Full Name" type="text" className='form_input_home'/><br/>
        <span style={{display:"flex",width:"100%",justifyContent:"space-around"}}>
        <select name="code" id="code" style={{width:"20%",border:"none",borderBottom:"1px solid gray",outline:"none"}}>
        <option value="india">+91</option>
       
        </select>
        <input placeholder="Enter Phone Number" type="phone"  style ={{width:"70%",border:"none",borderBottom:"1px solid gray",outline:"none",padding:"10px"}}/>
        </span>
        <br/>
        <input placeholder="Enter Email ID" type="email" className='form_input_home'/><br/>

      <span className='home_frm_btn'>
    <button className='home_frm_btn_get'>Get Started</button>
    <button className='home_frm_btn_learn'>learn more</button>
    </span>  
       </div>
      </div>
      <div data-aos="zoom-in-left" className='home_top_ctr_right'>
       <img style={{width:"90%" ,height:"70%",scale:"1.1"}}src={homeImgTop} alt=''/>
      </div>
     </div>

     <div data-aos="zoom-in" style={{minHeight:"50vh",padding:"2rem",display:"flex",alignItems:"center"}}>
    <Swiper
        spaceBetween={30}
      
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
        
          clickable: true,
        }}
        breakpoints={{
            // when window width is <= 767px
            767: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            // when window width is <= 991px
            991: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // when window width is <= 1199px
            1199: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
       
       
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div style={{display:"flex",flexDirection:"column",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",padding:"20px",borderRadius:"10px"}}>

            <img style={{borderRadius:"50%" ,width:"40px",height:"40px"}} src={sliderImg1} alt=''/>
           <span><b>Motor Insurance</b></span>
           <span style={{textAlign:"justify"}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo doloribus ipsam error doloremque, minus eveniet dolor ratione autem ipsum officia? Officia ducimus deserunt dignissimos harum accusamus et similique eveniet mollitia?</span>
           <span style={{color:"green",marginTop:"1rem",fontWeight:"600"}}><small>2 Categories</small></span>
            </div>
           


            
         

        </SwiperSlide>
        <SwiperSlide>
            <div style={{display:"flex",flexDirection:"column",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",padding:"20px",borderRadius:"10px"}}>

            <img style={{borderRadius:"50%" ,width:"40px",height:"40px"}} src={sliderImg2} alt=''/>
           <span><b>Home loans</b></span>
           <span style={{textAlign:"justify"}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo doloribus ipsam error doloremque, minus eveniet dolor ratione autem ipsum officia? Officia ducimus deserunt dignissimos harum accusamus et similique eveniet mollitia?</span>
           <span style={{color:"green",marginTop:"1rem",fontWeight:"600"}}><small>2 Categories</small></span>
            </div>
           


            
         

        </SwiperSlide>
        <SwiperSlide>
            <div style={{display:"flex",flexDirection:"column",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",padding:"20px",borderRadius:"10px"}}>

            <img style={{borderRadius:"50%" ,width:"40px",height:"40px"}} src={sliderImg3} alt=''/>
           <span><b>Mutual Funds</b></span>
           <span style={{textAlign:"justify"}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo doloribus ipsam error doloremque, minus eveniet dolor ratione autem ipsum officia? Officia ducimus deserunt dignissimos harum accusamus et similique eveniet mollitia?</span>
           <span style={{color:"green",marginTop:"1rem",fontWeight:"600"}}><small>2 Categories</small></span>
            </div>
           


            
         

        </SwiperSlide>
        <SwiperSlide>
            <div style={{display:"flex",flexDirection:"column",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",padding:"20px",borderRadius:"10px"}}>

            <img style={{borderRadius:"50%" ,width:"40px",height:"40px"}} src={sliderImg4} alt=''/>
           <span><b>Life Insurance</b></span>
           <span style={{textAlign:"justify"}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo doloribus ipsam error doloremque, minus eveniet dolor ratione autem ipsum officia? Officia ducimus deserunt dignissimos harum accusamus et similique eveniet mollitia?</span>
           <span style={{color:"green",marginTop:"1rem",fontWeight:"600"}}><small>2 Categories</small></span>
            </div>
           


            
         

        </SwiperSlide>
        <SwiperSlide>
            <div style={{display:"flex",flexDirection:"column",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",padding:"20px",borderRadius:"10px"}}>

            <img style={{borderRadius:"50%" ,width:"40px",height:"40px"}} src={sliderImg5} alt=''/>
           <span><b>Life Insurance</b></span>
           <span style={{textAlign:"justify"}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo doloribus ipsam error doloremque, minus eveniet dolor ratione autem ipsum officia? Officia ducimus deserunt dignissimos harum accusamus et similique eveniet mollitia?</span>
           <span style={{color:"green",marginTop:"1rem",fontWeight:"600"}}><small>2 Categories</small></span>
            </div>
           


            
         

        </SwiperSlide>
      </Swiper>
     </div>

     <div data-aos="zoom-in" className='home_second_ctr'>
        
            <span style={{fontSize:"35px",fontWeight:"600",color:"#092854",marginBottom:"2rem"}}>Our Life Insurance Partners</span>
             <div  style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"wrap"}}>
               <img className="partnerImg" src={partnerImg1} alt=''/>
               <img className="partnerImg" src={partnerImg2} alt=''/>
               <img className="partnerImg" src={partnerImg3} alt=''/>
               <img className="partnerImg" src={partnerImg4} alt=''/>
               <img className="partnerImg" src={partnerImg5} alt=''/>
             </div>
       
      
     </div>
     <div  data-aos="zoom-in" className='home_second_ctr'>
        <span   style={{fontSize:"35px",fontWeight:"600",color:"#092854",marginBottom:"1rem"}}>Our Mutual Funds Partners</span>
             <div  style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"wrap"}}>
               <img className="" src={mfundsImg1} alt=''/>
               <img className="partnerImg" src={mfundsImg2} alt=''/>
               <img className="partnerImg" src={mfundsImg3} alt=''/>
               <img className="partnerImg" src={mfundsImg4} alt=''/>
               <img className="partnerImg" src={mfundsImg5} alt=''/>
             </div>

        </div>

        <div data-aos="zoom-in" className='home_second_ctr' >
        <span style={{fontSize:"35px",fontWeight:"600",color:"#092854",marginBottom:"2rem"}}>Our General Insurance Partners</span>
             <div  style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"wrap"}}>
               <img className="" src={mfundsImg1} alt=''/>
               <img className="partnerImg" src={genInsImg1} alt=''/>
               <img className="partnerImg" src={genInsImg2} alt=''/>
         
             </div>

        </div>
        <div className='home_third_ctr' >
            <img src={graffiti} alt='' style={{width:"100%"}}/>
        </div>
        <div className='home_blogs_ctr' >
        <div className='home_blogs_ctr_top_text' >Latest from the blogs</div>
             <div className="home_blogs_ctr_content" >
             <div data-aos="zoom-in" className='home_blogs_ctr_card' >
               <img className="home_blogs_ctr_content_img" src={blogImg3}  alt=''/>
               <span className="home_blogs_ctr_content_blogHead" >
                <b>
                Protecting your future:A guide to comprehensive Insurance coverage
                </b>
               </span>
              <span className="home_blogs_ctr_content_desc" >Safeguarding your future means more than just hoping for the best—it's about proactive planning. In this guide, we delve into the world of comprehensive insurance coverage......</span>
              <span style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:"15px"}}>
                <img src={blogAuthor1} alt=''/>
                <b>
                Satish chandra

                </b>
             

              </span>
             </div>
             <div data-aos="zoom-in" className='home_blogs_ctr_card' >
               <img className="home_blogs_ctr_content_img" src={blogImg1}  alt=''/>
               <span className="home_blogs_ctr_content_blogHead" >
               <b>
               Navigating Life's Uncertainties: Why Insurance is Your Best Asset
                </b>
               </span>
              <span className="home_blogs_ctr_content_desc" >Life is full of unexpected twists and turns, but with the right insurance coverage, you can navigate these uncertainties with confidence. Discover why insurance isn't just another......</span>
              <span  style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:"15px"}}>
              <img src={blogAuthor2} alt=''/>
              <b>
                Abhisek Singh

                </b>

              </span>
             </div>
             <div data-aos="zoom-in" className='home_blogs_ctr_card' >
               <img className="home_blogs_ctr_content_img" src={blogImg2}  alt=''/>
               <span className="home_blogs_ctr_content_blogHead"  >
               <b>
               Beyond the Unexpected: How Health Insurance Safeguards Your Loved Ones
                </b>
               </span>
              <span className="home_blogs_ctr_content_desc" >Insurance isn't just about protection—it's about empowerment. By understanding the myriad benefits of insurance, you can take control of your financial future, confidently......</span>
              <span  style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:"15px"}}>
              <img src={blogAuthor3} alt=''/>
              <b>
               Samar Khan

                </b>

              </span>
             </div>
             </div>

        </div>
    </div>
  
    </>
}
export default Home