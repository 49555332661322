import './footer.css'
import vectorImg from '../../assets/Vector.png';

const Footer = () =>{
    return <>
   <div className='ftr_box'>
    <div className='ftr_links_box'>
    <div className='ftr_links_box_col'>
        <span className='ftr_links_box_head_Co'> <h1 className=''>Finwelco</h1></span>
        <span style={{fontSize:"10px"}}><b>A DIVISION OF DMT GROUP OF COMPANIES</b></span>
        <ul style={{marginTop:"2rem"}}>
            <li>
                Vishal Chamber,Sector-18 Noida
            </li>
            <li>
                +91 9818734488
            </li>
            <li>
                info@finwelco.com
            </li>
        </ul>
        
    </div>

    <div className='ftr_links_box_col'>
        <span className='ftr_links_box_head'>Recent Posts</span>
        <ul>
            <li>
            <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/40C057/long-arrow-right.png" alt="long-arrow-right"/>
            <span style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                <span>5 Benefits of Insurance</span>
                <span><small style={{color:"gray"}}>03 Jun 2024</small></span>
                 </span>
            </li>
            <li>
            <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/40C057/long-arrow-right.png" alt="long-arrow-right"/>
            <span style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                <span>Why Health Insurance is Necessary in 2024</span>
                <span><small style={{color:"gray"}}>03 Jun 2024</small></span>
                 </span>
            </li>
            <li>
            <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/40C057/long-arrow-right.png" alt="long-arrow-right"/>
            <span style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                <span>Mutual Funds:The ever Expanding Market</span>
                <span><small style={{color:"gray"}}>03 Jun 2024</small></span>
                 </span>
            </li>
        </ul>
        
    </div>
    <div className='ftr_links_box_col'>
        <span className='ftr_links_box_head'>Extra Links</span>
        <ul>
            <li>
            <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/40C057/long-arrow-right.png" alt="long-arrow-right"/>
            <span style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                <span>Follow us on Facebook</span>
                <span><small style={{color:"gray"}}></small></span>
                 </span>
            </li>
            <li>
            <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/40C057/long-arrow-right.png" alt="long-arrow-right"/>
            <span style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                <span>Best Insurance companies in India</span>
                <span><small style={{color:"gray"}}></small></span>
                 </span>
            </li>
            <li>
            <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/40C057/long-arrow-right.png" alt="long-arrow-right"/>
            <span style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                <span>Need a Better Insurance plan?</span>
                <span><small style={{color:"gray"}}></small></span>
                 </span>
            </li>
            <li>
            <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/40C057/long-arrow-right.png" alt="long-arrow-right"/>
            <span style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                <span>Mutual Funds Benefits</span>
                <span><small style={{color:"gray"}}></small></span>
                 </span>
            </li>
            <li>
            <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/40C057/long-arrow-right.png" alt="long-arrow-right"/>
            <span style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                <span>Our Team Events</span>
                <span><small style={{color:"gray"}}></small></span>
                 </span>
            </li>
        </ul>
    </div>
    <div className='ftr_links_box_col'>
        <span className='ftr_links_box_head'>Subscribe</span>
        <ul>
            <li style={{color:"gray"}}>Get the latest report about the insurance and company profile</li>
            <li >
                <input placeholder="Enter Email" style={{padding:"1rem",border:"none",outline:"none",height:"35px",borderBottomLeftRadius:"5px",borderTopLeftRadius:"5px"}} type='email'/>
                <span style={{padding:"1rem" ,border:"none", backgroundColor: '#0FB583',height:"35px",display:"flex",alignItems:"center",justifyContent:"center",borderBottomRightRadius:"5px",borderTopRightRadius:"5px"}}><img src={vectorImg} alt='vecx'/></span>
            </li>
            <li style={{width:"60%",display:"flex",justifyContent:"space-around"}}>
            <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/FFFFFF/facebook-new.png" alt="facebook-new"/>
            <img width="26" height="26" src="https://img.icons8.com/glyph-neue/64/FFFFFF/twitter-circled.png" alt="twitter-circled"/>
            <img width="26" height="26" src="https://img.icons8.com/ios-filled/50/FFFFFF/youtube-play.png" alt="youtube-play"/>
            <img width="26" height="26" src="https://img.icons8.com/glyph-neue/64/FFFFFF/instagram-new--v1.png" alt="instagram-new--v1"/>
            </li>
        </ul>
    </div>
    </div>
    <div className='ftr_btm_content'>
   <small>&#xa9; 2024 Insurance.All Rights Reserved.</small> 
    </div>

   </div>
    </>
}
export default Footer;